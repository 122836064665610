#quality-and-approvals {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: initial !important;
}
#quality-and-approvals h3 {
  font-size: 2em;
  line-height: 1.3;
}
#quality-and-approvals h4 {
  font-size: 1.5625em;
  line-height: 1.6;
  color: #DF3035;
}
#quality-and-approvals h3, #quality-and-approvals h4 {
  width: 100%;
  text-align: center;
}
#quality-and-approvals > a.button {
  margin: 0 auto;
}
#quality-and-approvals tabbed-content {
  margin-top: var(--spacer0);
}
#quality-and-approvals tabbed-content .tab-content {
  padding: var(--spacer1) 0;
}
#quality-and-approvals tabbed-content .tab-content:before {
  content: "";
  background-color: inherit;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  width: 100vw;
  height: 100%;
  transform: translateX(var(--translateX, -50%));
  background: inherit;
}
@media (max-width: 980px) {
  #quality-and-approvals tabbed-content .tab-content {
    padding-bottom: 0;
  }
}
body[data-element-id="132902"] #quality-and-approvals {
  padding-top: 0;
}