@use '_modules' as *;

#quality-and-approvals {

  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: initial !important;
  // padding-bottom:0; 

  h3 { @include heading-h2; }
  h4 { @include heading-h3; color:$red }
  h3, h4 {
    width:100%; 
    text-align: center;;
  }
  > a.button {
    margin:0 auto; 
  }

  tabbed-content {
    margin-top:$spacer0; 

    .tab-content {
      @include background-blowout { background:inherit; }
      padding:$spacer 0; 
      @include break-in(small-medium) {
        padding-bottom:0; 
      }
    }

    .introduction {
      // max-width:$media-small-medium;
    }

    // document-viewer {
    //   .container {
    //     position: relative;
    //     // padding-left:$gutter; 
    //     // padding-right:$gutter; 
    //     // left:$gutter-;
    //   }
    //   max-width:$media-large;
    // }
  }
}

body[data-element-id="132902"] #quality-and-approvals { padding-top:0; }